const actions = {
    async nuxtServerInit(store) {
        // its important we load the attributes first!
        await store.dispatch("attribute/loadAll");
        // otherwise this will fail...
        await store.dispatch("navigation/loadContentTree");
    },
};

export default {
    actions,
    strict: false,
};
