

import Vue from "vue";

export default Vue.extend({
  name: "StringAttributeValue",
  props: {
    attrValue: {
      type: Object,
      default: () => ({
        value: "",
      }),
    },
  },
});
