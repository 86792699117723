
import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "ColorAttributeValue",
  props: {
    attrValue: {
      type: Object as PropType<{ value: string }>,
      default: () => ({
        value: "",
      }),
    },
  },
  computed: {
    hexColor(): string {
      return this.attrValue.value;
    },
  },
});
