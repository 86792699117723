import {GetterTree} from "vuex";
import {IdentifiableObject} from "../model/identifiable-object";

export const state = () => ({
  // Remember: It's not possible to use a class here (like EqualityKeyMap) since vuejs will throw away all prototype information
  // (moste likely due to reactivity)
  typeName2Ids: {} as Record<string, Map<number, IdentifiableObject>>,
});

export type ObjectStoreState = ReturnType<typeof state>;

export const mutations = {
  setObjects(state: ObjectStoreState, data: { typeName: string, objects: Array<IdentifiableObject> }) {
    let id2Objects: Map<number, IdentifiableObject> = state.typeName2Ids[data.typeName];
    if (id2Objects == null) {
      id2Objects = new Map();
      state.typeName2Ids[data.typeName] = id2Objects;
    }

    data.objects.forEach((it) => {
      id2Objects.set(it.id as number, it);
    });
  },

  removeBy<T extends IdentifiableObject>(state: ObjectStoreState, data: { typeName: string, predicate: (t: T) => boolean }): void {
    const subMap = state.typeName2Ids[data.typeName];
    if (subMap == null) {
      return;
    }
    for (const [key, obj] of subMap) {
      if (data.predicate(obj as T)) {
        subMap.delete(key);
      }
    }
  },

  clear(state: ObjectStoreState): void {
    state.typeName2Ids = {};
  }
};

export const getters: GetterTree<ObjectStoreState, any> = {
  /**
   * @return `null` if no data was put inside the store. Otherwise, will return all objects for this particular type.
   * @param state
   */
  getAllObjectsByType: <T extends IdentifiableObject>(state: ObjectStoreState) => (typeName: string): Array<T> | null => {
    const typeName2Id = state.typeName2Ids[typeName];
    if (typeName2Id == null) {
      return null;
    }
    return Array.from(typeName2Id.values()) as any;
  },

  getAllObjectsByTypeMap: <T extends IdentifiableObject>(state: ObjectStoreState) => (typeName: string): Map<number, T> | null => {
    const typeName2Id = state.typeName2Ids[typeName];
    if (typeName2Id == null) {
      return null;
    }
    return typeName2Id as Map<number, T>;
  },

  getObject: (state: ObjectStoreState) => (typeName: string, id: number): IdentifiableObject | undefined => {
    const typeName2Id = state.typeName2Ids[typeName];
    if (typeName2Id == null) {
      return undefined;
    }

    const obj = typeName2Id.get(id);
    if (obj == null) {
      return undefined;
    }
    return obj;
  },

  getAllKeysFroType: (state: ObjectStoreState) => (typename: string): Array<number> | null => {
    return Array.from(state.typeName2Ids[typename].keys());
  },
};

export interface OrderStore {
  getAllObjectsByType<T extends IdentifiableObject>(typeName: string): Array<T> | null;

  returnMissingKeys(typeName: string, expectedKeys: Array<number>): Array<number>;
}

declare module "vuex/types/index" {
  interface Store<S> {
    $orderStore: OrderStore;
  }
}
