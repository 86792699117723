import {minBy} from "lodash";
import {UNSAVED_OBJECT_ID} from "../model/ProductBusinessLogic";

export const state = () => ({
/** @type{Array<Address>} */
addressList: [],
});

export const mutations = {

  /**
   *
   * @param state
   * @param {Array<Address>} address
   */
  setAddress(state, address) {
    state.addressList = address;
  },

  removeAddress(state, id) {
    const elementIndex = state.addressList.findIndex(element => element.id === id);
    state.addressList.splice(elementIndex, 1);
    // remove(state.addressList, element => element.id === id);
  },

  /**
   *
   * @param state
   * @param {Address} address
   */
  addAddress(state, address) {
    if (address.id === UNSAVED_OBJECT_ID) {
      const maxValue = minBy(state.addressList, "id");
      address.id = maxValue !== undefined ? maxValue.id - 1 : -2;
    }
    state.addressList.push(address);
  },

};
