
import Vue from "vue";
import {BIcon, BIconPersonFill, BIconPlus, BIconstack} from "bootstrap-vue";

export default Vue.extend({
  name: "RegisterIcon",
  components: {
    BIconstack,
    // eslint-disable-next-line vue/no-unused-components
    BIconPlus,
    // eslint-disable-next-line
    BIcon,
    // eslint-disable-next-line
    BIconPersonFill,
  },
});
