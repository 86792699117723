
import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "BooleanAttributeValue",
  props: {
    attrValue: {
      type: Object as PropType<{value: string}>,
      default: () => ({
        value: "",
      }),
    },
  },
  computed: {
    symbol(): string {
      return this.attrValue.value || this.attrValue.value === "true" ? "✓" : "✕";
    },
  },
});
