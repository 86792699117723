// export default function ({ app, error }) {
export default function({app, redirect}) {
  const isLoggedIn = !!app.$auth.isLoggedIn();
  if (!isLoggedIn) {
    redirect("/user/login");
    // error({ errorCode: 403, message: 'You are not allowed to see this' })
  }
  // error({
  //   errorCode: 403,
  //   message: "This will always fail"
  // });
}
