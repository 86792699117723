<template>
  <client-only>
    <b-link :href="getHref()">
      <b-icon-envelope-fill/>
      <span class="nav-link-text">{{ getValue() }}</span>
    </b-link>
  </client-only>
</template>
<script>

import {BIconEnvelopeFill} from "bootstrap-vue";
import {EmailLinkLogic} from "@/components/util/EmailLinkLogic";

export default {
  name: "EmailLink",
  components: {
    BIconEnvelopeFill,
  },
  methods: {
    getValue() {
      return EmailLinkLogic.getAddress(this.$config.domain);
    },
    getHref() {
      return EmailLinkLogic.getHref(this.$config.domain);
    },
  },
};
</script>
