const middleware = {}

middleware['hasAdminPermissions'] = require('../middleware/hasAdminPermissions.js')
middleware['hasAdminPermissions'] = middleware['hasAdminPermissions'].default || middleware['hasAdminPermissions']

middleware['hasAdminPermissions'] = require('../middleware/hasAdminPermissions.ts')
middleware['hasAdminPermissions'] = middleware['hasAdminPermissions'].default || middleware['hasAdminPermissions']

middleware['hasPermission'] = require('../middleware/hasPermission.js')
middleware['hasPermission'] = middleware['hasPermission'].default || middleware['hasPermission']

middleware['hasPermission'] = require('../middleware/hasPermission.ts')
middleware['hasPermission'] = middleware['hasPermission'].default || middleware['hasPermission']

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

export default middleware
