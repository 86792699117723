
import Vue from "vue";
import StringAttributeValue from "../../components/product/attribute-value/StringAttributeValue.vue";
import BooleanAttributeValue from "../../components/product/attribute-value/BooleanAttributeValue.vue";
import CurrencyAttributeValue from "../../components/product/attribute-value/CurrencyAttributeValue.vue";
import ColorAttributeValue from "../../components/product/attribute-value/ColorAttributeValue.vue";
import {getTypeFromAttribute} from "../../model/ProductBusinessLogic";
import {ProductAttributeTypes} from "../../model/shared/ProductAttributeTypes";
import {ProductAttributeValue} from "../../model/rest/data-contracts";

export default Vue.extend({
  name: "ProductValueRenderer",
  components: {
    StringAttributeValue,
    ColorAttributeValue,
    BooleanAttributeValue,
    CurrencyAttributeValue,
  },
  props: {
    renderAsList: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    hasAttributes() {
      return this.values.length > 0;
    },
  },
  methods: {
    /**
     * @param {ProductAttributeValue} attributeValue
     * @return {string}
     */
    getAttributeName(attributeValue: ProductAttributeValue): string {
      return attributeValue.productAttribute.attribute?.name!;
    },
    /**
     *
     * @param {ProductAttributeValue} value
     */
    getValueComponentType(value: ProductAttributeValue) {
      // @ts-ignore
      if (getTypeFromAttribute(value.productAttribute) === ProductAttributeTypes.COLOR.name) {
        return "ColorAttributeValue";
      }

      // @ts-ignore
      if (getTypeFromAttribute(value.productAttribute) === ProductAttributeTypes.CURRENCY.name) {
        return "CurrencyAttributeValue";
      }
      // @ts-ignore
      if (getTypeFromAttribute(value.productAttribute) === ProductAttributeTypes.BOOLEAN.name) {
        return "BooleanAttributeValue";
      }
      // Fallback, just renders whats inside the value
      return "StringAttributeValue";
    },
    /**
     * Returns an object representing the properties of the component.
     * @param value
     * @returns {{attrValue}}
     */
    getValueProps(value: any): {attrValue: any} {
      return {
        attrValue: value,
      };
    },
  },
});
