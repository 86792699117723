import {ProductModelFactory} from "../model/product/ProductModelFactory";

export const state = () => ({
  selectedProductType: ProductModelFactory.createEmptyProductType(),
});
export const mutations = {
  setAdminProductSelectedProductType(state, type) {
    state.selectedProductType = type;
  }
}
