
import Vue from "vue";

import {isCurrencyObject} from "kistenkonfigurator/build/util/currency-util";

export default Vue.extend({
  name: "PriceLine",
  props: {
    label: {
      type: String,
      default: () => "",
      required: true,
    },
    gap: {
      type: Boolean,
      default: () => true,
    },
    value: {
      type: [Number, String, Object], // currency object
      default: () => "",
      required: true,
    },
  },
  computed: {
    valueAsString() {
      return isCurrencyObject(this.value) ? this.value.format() : this.value;
    },
  },

});
