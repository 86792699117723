

import {BIconDoorOpenFill, BIconPersonFill, BIconTelephoneFill} from "bootstrap-vue";
import Vue from "vue";
import {CategoryNode, ContentNode, NavigationController} from "../../model/product/NavigationController";
// @ts-ignore
import NavigationCartButton from "../../components/cart/NavigationCartButton.vue";
// @ts-ignore
import RegisterIcon from "../../components/user/RegisterIcon.vue";
// @ts-ignore
import EmailLink from "../../components/util/EmailLink.vue";

export default Vue.extend({
  name: "NavigationComponent",
  components: {
    EmailLink,
    RegisterIcon,
    NavigationCartButton,
    BIconPersonFill,
    BIconTelephoneFill,
    BIconDoorOpenFill,
  },
  data() {
    return {
      servicesPages: NavigationController.getServicesPages(),
    };
  },
  computed: {
    hasAdminPermission(): boolean {
      return this.isLoggedIn && this.$auth.hasAdminPermission();
    },
    contentTree(): CategoryNode {
      return this.$bl.navigationRepository.contentTree();
    },
    isLoggedIn(): boolean {
      return this.$auth.isLoggedIn();
    },
    isQuickOrderVisible(): boolean {
      return this.$auth.hasQuickOrder();
    },
    isMoebel(): boolean {
      return this.$config.shopMode === "moebel";
    },
  },
  methods: {
    hasContent(c: CategoryNode): boolean {
      return NavigationController.hasContent(this, c);
    },
    async logout(): Promise<void> {
      if (this.$route.path !== "/") {
        await this.$router.push("/");
      }
      this.$auth.logout();
      if (process.client) {
        window.location.reload();
      }
    },
    getUrlFromChild(node: ContentNode): string {
      return NavigationController.toURL(node);
    },
    toVueId(c: CategoryNode): string {
      if (c.children != null) {
        return c.id + "category";
      }
      return c.id + "productType";
    },
  },
});
