
import Vue from "vue";
import CartTotal from "../../components/cart/CartTotal.vue";
import {CartService} from "../../model/graphql/CartService";
import CartList from "./CartList.vue";

export default Vue.extend({
  components: {
    CartTotal,
    CartList,
  },
  computed: {
    isCartEmpty(): boolean {
      return this.getCartService().getCartModel().items.length === 0;
    },
  },
  methods: {
    getCartService(): CartService {
      return this.$bl.cartService;
    },
  }
});
