<template>
  <b-nav-item-dropdown id="cart-popover" right>
    <template v-slot:button-content>
      <span @click="click">
        <cart-icon/>
        <span class="nav-link-text">Warenkorb</span>
        <span v-show="positionCount > 0" class="cart-position-counter">
          {{ positionCount }}
        </span>
      </span>
    </template>
    <template v-slot:default>
      <cart-popover/>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import CartPopover from "@/components/cart/CartPopover";
import CartIcon from "@/components/cart/CartIcon";

export default {
  name: "NavigationCartButton",
  components: {
    CartIcon,
    CartPopover,
  },
  computed: {
    positionCount() {
      return this.$store.getters["cart/getCount"]();
    },
  },
  methods: {
    click(e) {
      const mqString = "(max-width: 575.98px)";
      const mediaQueryList = window.matchMedia(mqString);
      const isMobile = mediaQueryList.matches;
      if (isMobile) {
        e.stopPropagation();
        this.$router.push("/checkout/");
      }
    },
  },
};
</script>
