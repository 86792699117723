<template>
  <footer>
    <b-container>
      <div class="row">
        <div class="col-3 col-md">
          <h5>Bodmer AG</h5>
          <ul class="list-unstyled text-small">
            <li><a href="/geschichte">Geschichte</a></li>
            <li><a href="/kontakt">Kontakt</a></li>
            <!--<li><a href="/news">News</a></li>-->
            <li><a href="/labels">Labels</a></li>
          </ul>
        </div>
        <div class="col-3 col-md">
          <!--            <h5>Dienstleistungen</h5>-->
          <!--            <ul class="list-unstyled text-small">-->
          <!--              <li-->
          <!--                v-for="servicePage in servicePages"-->
          <!--                :key="servicePage.url"-->
          <!--              >-->
          <!--                <a :href="servicePage.url">{{ $t(servicePage.navLabel) }}</a>-->
          <!--              </li>-->
          <!--            </ul>-->
        </div>
        <div class="col-3 col-md">
          <!--            <h5>Hilfe</h5>-->
          <!--            <ul class="list-unstyled text-small">-->
          <!--              <li><a class="text-muted" href="#">Bestellung</a></li>-->
          <!--              <li><a class="text-muted" href="#">Qualität</a></li>-->
          <!--            </ul>-->
        </div>
        <div class="col-3 col-md">
          <!--            <h5>Rechnungsart</h5>-->
          <!--            <ul class="list-unstyled text-small">-->
          <!--              <li><a class="text-muted" href="#">Rechnung</a></li>-->
          <!--              <li><a class="text-muted" href="#">Postfinance</a></li>-->
          <!--              <li><a class="text-muted" href="#">PayPal</a></li>-->
          <!--            </ul>-->
        </div>
      </div>
      <div id="connections" class="row">
        <div class="col-12 text-right">
          <div id="development">
            <span>Developed by</span><a href="https://viseon.ch/" title="Developed by Viseon"><img class="icon-image" src="/images/icons/viseon.svg" alt="Viseon - Softwareentwicklung mit Vision"/></a>
          </div>
<!--          <a class="big-font" href="https://www.instagram.com/palettenmoebel_ch/"><b-icon-instagram alt="Besuch uns auf Instagram"/></a>-->
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-5 col-md">
          <p class="mb-0">
            © {{ new Date().getFullYear() }} Bodmer AG
          </p>
        </div>
        <div class="col-7 col-md text-right disclaimer">
          <ul class="list-unstyled text-small mb-0">
            <li><a href="/agb">AGB</a></li><li><a href="/impressum">Impressum</a></li><li><a href="/datenschutz">Datenschutz</a></li>
          </ul>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
import {BIconInstagram} from "bootstrap-vue";
export default {
  name: "FooterVerpackung",
  components: {
    BIconInstagram,
  },
};
</script>
