/**
 * This store is only a helper for reactivity.
 */
export const state = () => ({
  /** @type {any|null} */
  jwtPayload: null,
});
export const mutations = {
  /**
   * @param state
   * @param {any} jwtPayload
   */
  setState(state, jwtPayload) {
    state.jwtPayload = jwtPayload;
  },
};
