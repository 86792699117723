<template>
  <b-icon-cart-fill/>
</template>

<script>
import {BIconCartFill} from "bootstrap-vue";

export default {
  name: "CartIcon",
  components: {
    BIconCartFill,
  },
};
</script>
