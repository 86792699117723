<template>
  <div id="root" :class="{verpackung: isVerpackung, moebel: isMoebel, developmentMode: isRunningLocally}">
    <div id="scrollTarget"/>
    <navigation-component/>
    <nuxt/>
    <component :is="footerComponent"/>
    <client-only>
      <cookie-consent/>
    </client-only>
  </div>
</template>

<script>
import NavigationComponent from "../components/navigation/Navigation";
import FooterMoebel from "@/components/footer/footer-moebel";
import FooterVerpackung from "@/components/footer/footer-verpackung";
import CookieConsent from "~/components/cookie/CookieConsent";

export default {
  components: {
    CookieConsent,
    NavigationComponent,
    FooterMoebel,
    FooterVerpackung,
  },
  data() {
    return {
      isVerpackung: this.$config.shopMode === "verpackung",
      isMoebel: this.$config.shopMode === "moebel",
      footerComponent: this.$config.shopMode === "moebel" ? FooterMoebel : FooterVerpackung,
      isRunningLocally: false,
    };
  },
  mounted() {
    // initialize Google Tag Manager
    const gtmId = this.$config.GTM || "";
    if (gtmId.length > 0) {
      let externalScript = document.createElement("script");
      externalScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','" + gtmId + "');";
      document.head.appendChild(externalScript);
    }

    this.isRunningLocally = window.location.host.startsWith("localhost");
  },
  head() {
    const i18nHeaders = this.$nuxtI18nHead({addSeoAttributes: true});
    return {
      ...i18nHeaders,
      title: this.isMoebel ? this.$t("defaults.pageTitleMoebel") : this.$t("defaults.pageTitle"),
      link: [
        {
          rel: "icon",
          type: "image/svg+xml",
          href: this.isMoebel ? "/Palettenmoebel_favicon.svg" : "/favicon.svg",
        },
        {
          rel: "canonical",
          href: `https://${this.$config.domain}${this.$route.path}`,
        },
      ],
      meta: [
        {
          hid: "google-verification",
          name: "google-site-verification",
          content: this.isMoebel ? "OtLqN51mjkR__pqxWXF6prMTfyhfbDdvdhC8aU2fvoE" : "aChxROMBQIq2lJ8qCgzHtFR36qAa33cm1S6pnScVxgs"
        }
      ]
    };
  },
};
</script>
