

import Vue from "vue";
import {GoogleTracking, LOCAL_STORAGE_KEY} from "../../model/google/GoogleTracking";

interface State {
  showConsentModal: boolean;
}

export default Vue.extend({
  name: "CookieConsent",
  data(): State {
    return {
      showConsentModal: true,
    }
  },
  mounted(): void {
    const valueInStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
    this.showConsentModal = valueInStorage == null;
    const isTrackingAllowed = valueInStorage === "true";
    GoogleTracking.INSTANCE.updateConsentState(isTrackingAllowed);
  },
  methods: {
    cookiesAccepted(): void {
      this.showConsentModal = false;
      localStorage.setItem(LOCAL_STORAGE_KEY, "true");
      GoogleTracking.INSTANCE.updateConsentState(true);
    },
    cookiesDecline(): void {
      this.showConsentModal = false;
      localStorage.setItem(LOCAL_STORAGE_KEY, "false");
      GoogleTracking.INSTANCE.updateConsentState(false);
    }
  }
});
