
import Vue, {PropType} from "vue";
import {CHF} from "kistenkonfigurator/build/util/currency-util";

export default Vue.extend({
  name: "CurrencyAttributeValue",
  props: {
    attrValue: {
      type: Object as PropType<{value: string}>,
      default: () => ({
        value: "",
      }),
    },
  },
  computed: {
    currency(): string {
      return CHF(this.attrValue.value).format();
    },
  },
});
