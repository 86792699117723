
import Vue, {PropType} from "vue";
import currency from "currency.js";
import PriceLine from "../../components/util/PriceLine.vue";
import {calculateVAT} from "../../model/ProductBusinessLogic";
import ValidatedField from "../../components/util/form/ValidatedField.vue";
import {ProductModelFactory} from "../../model/product/ProductModelFactory";
import {CustomerOrderSettings} from "../../model/rest/data-contracts";
import {CartModel} from "../../model/checkout/CartModel";

interface State {
  orderSettings: CustomerOrderSettings;
}

export default Vue.extend({
  name: "CartTotal",
  components: {
    ValidatedField,
    PriceLine,
  },
  props: {
    hideVat: {
      type: Boolean,
      default: () => false,
    },
    hideShippingZipCode: {
      type: Boolean,
      default: () => false,
    },
    model: {
      type: Object as PropType<CartModel>,
      required: true,
    },
  },
  data(): State {
    return {
      orderSettings: ProductModelFactory.createEmptyOrderSettings(),
    };
  },
  computed: {
    /**
     * @return boolean
     */
    needsNewCustomerOrderSettings(): boolean {
      return this.$auth.isLoggedIn() && this.$auth.getCustomerId() != null && (this.shippingZipCode == null || this.shippingZipCode === "");
    },
    discountLabel(): string {
      const discount = this.model.discount;
      if (discount == null || discount === 0) {
        return "";
      }
      return `(Rabatt ${(discount * 100)}%)`;
    },
    vat(): currency {
      return calculateVAT(this.total().add(this.shippingCost));
    },
    vatLabel(): string {
      return this.vat.format();
    },
    shippingZipCode: {
      set(value: string): void {
        this.$store.dispatch("cart/updateShippingCost", value);
      },
      get(): string {
        return this.$store.state.cart.shippingZipCode;
      },
    },
    shippingCost: {
      get(): currency {
        return this.model.shippingCost;
      },
    },
    shippingCostLabel(): string {
      return this.shippingCost.format();
    },
    totalLabel(): string {
      return this.total().format();
    },
  },
  watch: {
    needsNewCustomerOrderSettings: {
      immediate: true,
      handler() {
        if (this.needsNewCustomerOrderSettings) {
          this.$bl.customer.getCustomerSettings(this.$auth.getCustomerId())
              .then((result) => {
                this.orderSettings = result;
              });
        }
      },
    },
    orderSettings() {
      const preferredDeliveryAddress = this.orderSettings.preferredShippingAddress;
      if (preferredDeliveryAddress == null) {
        return;
      }

      const preferredZipCode = preferredDeliveryAddress.zipCode;
      if (preferredZipCode == null || preferredZipCode === "") {
        return;
      }

      this.shippingZipCode = preferredZipCode;
    },
  },
  methods: {
    total() {
      return this.model.productPriceWithDiscount;
    },
  },
});
