import {set} from "vue";
import {Context} from "@nuxt/types";
import {Dictionary, keyBy, remove} from "lodash";
import {warn} from "kistenkonfigurator/build/util/debug-util";
import {arrayToMapMultiValue, updateMultiValueMap} from "kistenkonfigurator/build/util/util";
import {CategoryNode, NavigationController} from "../model/product/NavigationController";
import {ProductModelFactory} from "../model/product/ProductModelFactory";
import {
  Category,
  CategoryProduct,
  CategoryUpdate,
  ProductType,
  ProductTypeAttribute,
  ProductTypeAttributeUpdate,
  ProductTypeUpdate,
} from "../model/rest/data-contracts";
import {ShopTypeId} from "../model/shared/ShopTypeId";
import {setOfArray} from "../util/object-util";
import {sortAttributes} from "../model/ProductBusinessLogic";

interface State {
  contentTree: CategoryNode;
  categories: Array<Category>,
  id2Categories: Dictionary<Category>,
  productTypes: Array<ProductType>,
  id2ProductTypes: Dictionary<ProductType>,
  categoryId2Products: Map<number, Array<CategoryProduct>>
  productId2Categories: Map<number, Array<CategoryProduct>>
}

export const state = () => ({
  contentTree: ProductModelFactory.createEmptyCategoryNode(),
  categories: [],
  id2Categories: {},
  productTypes: [],
  id2ProductTypes: {},
  categoryId2Products: new Map(),
  productId2Categories: new Map(),
} as State);

function _rebuildTree(state: any, shopTypeId: number) {
  const result = NavigationController.buildCategoryTree(
    shopTypeId != null ? shopTypeId : ShopTypeId.ANY,
    state.categories);

  set(state, "contentTree", result);
}

/**
 *
 * @private
 */
function _sortCategories(categories: Array<Category>) {
  if (categories == null) {
    return;
  }

  categories.sort(
    (a, b) => {
      return NavigationController.compare(a, b);
    });
}

function _removeChildFromTree(globalArray: Array<Category>, node: CategoryNode) {
  if (node.parent == null || node.parent.children == null) {
    return;
  }

  const index = node.parent.children.findIndex(it => it === node);
  if (index >= 0) {
    node.parent.children.splice(index, 1);
  }

  const globalIndex = globalArray.findIndex(it => it.id === node.id);
  if (globalIndex >= 0) {
    globalArray.splice(globalIndex, 1);
  }
}

export const mutations = {
  rebuildTree(this: Context, state: State) {
    _rebuildTree(state, this.$shopTypeHelper.id);
  },
  setTree(state: any, tree: CategoryNode) {
    state.contentTree = tree;
  },
  setCategories(this: Context, state: State, categories: Array<Category>) {
    _sortCategories(categories);
    state.categories = categories;
    state.id2Categories = keyBy(categories, "id");
  },
  appendCategory(state: State, category: CategoryNode) {
    if (category.children == null) {
      category.children = [];
    }

    // FIXME: Use binarySearch for correct insertion index
    state.categories.push(category);
    _sortCategories(state.categories);

    const parent = NavigationController.findCategoryNode(state.contentTree, category.parentId!);
    if (parent != null) {
      parent.children.push(category);
      category.parent = parent;
    }

    state.id2Categories[category.id!] = category;
  },
  changeCategory(state: State, changeParams: CategoryUpdate & { id: number }) {
    const category = state.id2Categories[changeParams.id!];
    if (category == null) {
      return;
    }

    Object.keys(changeParams)
      .filter(k => k !== "id")
      .forEach((key) => {
        (category as any)[key] = (changeParams as any)[key];
      });
  },
  removeCategory(state: State, id: number) {
    const categoryNode = state.id2Categories[id];
    if (categoryNode == null) {
      return;
    }
    delete state.id2Categories[id];

    _removeChildFromTree(state.categories, categoryNode as CategoryNode);
  },
  changeCategoryOrder(this: Context, state: State, categoryOrders: Array<{id: number, order: number}>): void {
    categoryOrders.forEach((it) => {
      state.id2Categories[it.id].order = it.order;
    });
    _rebuildTree(state, this.$shopTypeHelper.id);
  },
  swapCategoryOrder(this: Context, state: any, {
    sourceId,
    targetId,
  }: { sourceId: number, targetId: number }) {
    const sourceNode = state.id2Categories[sourceId];
    const targetNode = state.id2Categories[targetId];
    const sourceOrder = sourceNode.order;

    sourceNode.order = targetNode.order;
    targetNode.order = sourceOrder;

    // Actually, this is an error case: It makes no sense to change the order of categories which do not belong to the same parent.
    if (sourceNode.parentId !== targetNode.parentId) {
      _rebuildTree(state, this.$shopTypeHelper.id);
      return;
    }

    const parent = state.id2Categories[sourceNode.parentId];
    if (parent == null) {
      _rebuildTree(state, this.$shopTypeHelper.id);
      return;
    }

    _sortCategories(parent.children);
  },
  categoryProduct(state: State, categoryProducts: Array<CategoryProduct>): void {
    state.categoryId2Products = arrayToMapMultiValue(categoryProducts, "categoryId") as Map<number, Array<CategoryProduct>>;
    state.productId2Categories = arrayToMapMultiValue(categoryProducts, "productId") as Map<number, Array<CategoryProduct>>;
  },
  setProductTypes(state: State, productTypes: Array<ProductType>): void {
    productTypes.forEach((pt) => {
      sortAttributes(pt.attrs ?? []);
    });
    state.productTypes = productTypes;
    state.id2ProductTypes = keyBy(productTypes, "id");
  },
  /**
   *
   * @param state
   * @param {ProductType} params
   */
  changeProductType(state: any, params: ProductTypeUpdate & { id: number }) {
    const productType = state.id2ProductTypes[params.id];
    if (productType == null) {
      return;
    }

    Object.keys(params)
      .filter(k => k !== "id")
      .forEach((key) => {
        productType[key] = (params as any)[key];
        // node[key] = params[key];
      });
  },
  appendProductType(state: State, productType: ProductType) {
    if (productType.attrs == null) {
      productType.attrs = [];
    }
    state.productTypes.push(productType);
    state.id2ProductTypes[productType.id!] = productType;
  },
  removeProductType(state: State, id: number) {
    const productType = state.id2ProductTypes[id];
    if (productType == null) {
      return;
    }

    delete state.id2ProductTypes[id];
  },
  /**
   *
   * @param state
   * @param {number} productTypeId
   * @param {Array<number>} ids
   */
  removeAttributeFromProductType(state: any, {
    productTypeId,
    ids,
  }: { productTypeId: number, ids: Array<number> }) {
    if (ids.length === 0) {
      return;
    }

    /** @type {ProductType|undefined} */
    const pt = state.id2ProductTypes[productTypeId];
    const setIds = setOfArray(ids);
    const removePredicate = (ptAttr: ProductTypeAttribute) => setIds.has(ptAttr.id);
    remove(pt.attrs, removePredicate);
    sortAttributes(pt.attrs);
  },
  addAttributeToProductType(state: any, {
    productTypeId,
    newAttributes,
  }: { productTypeId: number, newAttributes: Array<ProductTypeAttribute> }) {
    if (newAttributes.length === 0) {
      return;
    }
    /** @type {ProductType|undefined} */
    const pt = state.id2ProductTypes[productTypeId];

    pt.attrs.push(...newAttributes);
    sortAttributes(pt.attrs);
  },
  swapAttributeOrder(state: any, {
    productTypeId,
    source,
    target,
  }: { productTypeId: number, source: number, target: number }) {
    const productType = state.id2ProductTypes[productTypeId];

    const sourceAttr = productType.attrs.find((ptAttr: ProductTypeAttribute) => ptAttr.id === source);
    const targetAttr = productType.attrs.find((ptAttr: ProductTypeAttribute) => ptAttr.id === target);

    const sourceOrder = sourceAttr.order;

    sourceAttr.order = targetAttr.order;
    targetAttr.order = sourceOrder;

    sortAttributes(productType.attrs);
  },
  /**
   *
   * @param state
   * @param {number} productTypeId
   * @param {ProductTypeAttributeUpdate} params
   */
  updateAttribute(state: any, {
    productTypeId,
    params,
  }: { productTypeId: number, params: ProductTypeAttributeUpdate }) {
    const productType = state.id2ProductTypes[productTypeId];
    if (productType == null) {
      warn(`ProductType not found ${productTypeId}`);
      return;
    }

    const attribute = productType.attrs.find((ptAttr: ProductTypeAttribute) => ptAttr.id === params.id);
    if (attribute == null) {
      warn(`ProductAttribute not found ${params.id}`);
      return;
    }

    Object.keys(params)
      .filter(k => k !== "id")
      .forEach((key) => {
        attribute[key] = (params as any)[key];
      });
  },
  updateCategoryProduct(state: State, params: { productId: number, newCategoryIds: Array<number>, removedCategoryIds: Array<number> }) {
    const newEntries = params.newCategoryIds.map(newCat => ({productId: params.productId, categoryId: newCat}));
    newEntries.forEach((newEntry) => {
      updateMultiValueMap(state.categoryId2Products, newEntry.categoryId, newEntry);
      updateMultiValueMap(state.productId2Categories, newEntry.productId, newEntry);
    });

    params.removedCategoryIds.forEach((categoryId: number) => {
      const productEntries = state.categoryId2Products.get(categoryId);
      if (productEntries != null) {
        state.categoryId2Products.set(categoryId, productEntries.filter(entry => entry.productId !== params.productId));
      }
      const categoryEntries = state.productId2Categories.get(params.productId);
      if (categoryEntries != null) {
        state.productId2Categories.set(params.productId, categoryEntries.filter(entry => entry.categoryId !== categoryId));
      }
    });
  },
};

export const getters = {
  getProductType: (state: State) => (id: number): ProductType | undefined => {
    const result = state.id2ProductTypes[id];
    if (result == null) {
      warn(`ProductType with id ${id} was not found.`);
      return undefined;
    }
    return result;
  },
  contentTree(state: State): CategoryNode {
    return state.contentTree;
  },
  categories(state: State): Array<Category> {
    return state.categories;
  },
  productTypes(state: State): Array<ProductType> {
    return state.productTypes;
  },
  getCategoryProductsById: (state: State) => (categoryId: number): Array<CategoryProduct> => {
    return state.categoryId2Products.get(categoryId) ?? [];
  },
  getAllCategoryProducts: (state: State) => (): Array<CategoryProduct> => {
    const values = Array.from(state.categoryId2Products.values());
    return values.flatMap(it => it);
  },
  getCategoryProductsByProductId: (state: State) => (productId: number): Array<CategoryProduct> => {
    return state.productId2Categories.get(productId) ?? [];
  },
  getCategoryBdId: (state: State) => (categoryId: number): Category => {
    return state.id2Categories[categoryId]!;
  },
};

export const actions = {
  async loadContentTree(this: Context, store: any) {
    const categoryRequest = await this.$bl.category.getAll();
    store.commit("setCategories", categoryRequest);

    // Has side effect!
    await this.$graphApi.productType.getAll();

    store.commit("rebuildTree");

    await this.$graphApi.categoryProductRest.getAll();
  },
};
