import {CheckoutMode} from "@/model/checkout/CheckoutProcess";
import {createEmptyQuoteModel} from "@/model/checkout/QuoteModel";
import {AddressUtil} from "@/model/customer/AddressUtil";
import {CustomRuntimeError} from "kistenkonfigurator/build/util/exception-util";
import {DeliveryTypeInsert, PaymentMethodEnum} from "@/model/rest/data-contracts";

export const state = () => ({
    /** @type {CheckoutMode} */
    checkoutMode: CheckoutMode.GUEST,
    /** @type {CustomerFormModel} */
    guestCustomer: undefined,
    /** @type {QuoteModel} */
    quoteModel: createEmptyQuoteModel(),
    /** @type {string} */
    orderReference: "",

    /** @type {Address} */
    deliveryAddress: AddressUtil.createEmptyAddress(),

    /** @type {number} */
    deliveryMode: DeliveryTypeInsert.DELIVERY,

    /** @type {Date} */
    requestedDeliveryDate: null,

    invoiceEmail: "",
    /**
     * Invoice postal address
     * @type {Address} */
    postalAddress: AddressUtil.createEmptyAddress(),

    /** @type{number} */
    paymentOption: PaymentMethodEnum.PREPAYMENT,

    orderQuoteData: null,

    /**
     * @type {string}
     */
    orderComment: "",
});

export const mutations = {
    /**
     * @param state
     * @param {CheckoutMode} checkoutMode
     */
    setCheckoutMode(state, checkoutMode) {
        state.checkoutMode = checkoutMode;
    },

    /**
     * @param state
     * @param {CustomerFormModel} guest
     */
    setGuestData(state, guest) {
        state.checkoutMode = CheckoutMode.GUEST;
        state.guestCustomer = guest;
    },

    /**
     * @param state
     * @param {QuoteModel} quoteModel
     */
    setQuoteModel(state, quoteModel) {
        state.quoteModel = quoteModel;
    },

    setOrderRef(state, ref) {
        state.orderReference = ref;
    },

    /**
     *
     * @param state
     * @param {Address} address
     */
    setDeliveryAddress(state, address) {
        state.deliveryAddress = address;
    },

    /**
     * @param state
     * @param {Date} date
     */
    setDesiredDeliveryDate(state, date) {
        state.requestedDeliveryDate = date;
    },
    /**
     *
     * @param state
     * @param {Address} address
     */
    setPostalAddress(state, address) {
        state.postalAddress = address;
    },

    /**
     *
     * @param state
     * @param {string} invoiceEmail
     */
    setInvoiceEmail(state, invoiceEmail) {
        state.invoiceEmail = invoiceEmail;
    },


    /**
     *
     * @param state
     * @param {DeliveryTypeInsert} method
     */
    setDeliveryMode(state, method) {
        if (!Object.values(DeliveryTypeInsert).includes(method)) {
            throw new CustomRuntimeError(
                `Value is not part of enum DeliveryMode: ${method}`);
        }
        state.deliveryMode = method;
    },

    /**
     *
     * @param state
     * @param {PaymentMethodEnum} option
     */
    setOption(state, option) {
        if (!Object.values(PaymentMethodEnum).includes(option)) {
            throw new CustomRuntimeError(
                `Value is not part of enum PaymentOption: ${option}`);
        }
        state.paymentOption = option;
    },

    setOrderQuoteData(state, orderQuoteData) {
        state.orderQuoteData = orderQuoteData;
    },

    clearOut(state) {
        state.checkoutMode = CheckoutMode.GUEST;
        state.guestCustomer = undefined;
        state.quoteModel = createEmptyQuoteModel();
        state.orderReference = "";
        state.deliveryAddress = null;
        state.requestedDeliveryDate = null;
        state.invoiceEmail = "";
        state.postalAddress = AddressUtil.createEmptyAddress();
        state.paymentOption = PaymentMethodEnum.PREPAYMENT;
        state.orderQuoteData = null;
    },

    setOrderComment(state, value) {
        state.orderComment = value;
    },
};

export const getters = {
    isValid(state) {
        return state.deliveryAddress !== null;
    },
};
