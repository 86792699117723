import { render, staticRenderFns } from "./PriceLine.vue?vue&type=template&id=984d1f76"
import script from "./PriceLine.vue?vue&type=script&lang=ts"
export * from "./PriceLine.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports