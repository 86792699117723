import {configure, extend, validate} from "vee-validate";
import {
  alpha,
  double,
  email,
  max,
  min,
  numeric,
  required,
} from "vee-validate/dist/rules";
import {provincesCanada, statesUSA} from "../components/admin/order/fedex/CountryList";

extend("required", required);
extend("alpha", alpha);
extend("email", email);
extend("min", min);
extend("max", max);
extend("numeric", numeric);
extend("double", double);

extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
});

const phoneRegEx = new RegExp("^\\+?[0-9\\s]{1,17}$");
extend("phone", {
  validate(value) {
    return phoneRegEx.test(value);
  },
});

extend("street", alpha);

extend("plz", {
  async validate(value) {
    const res = await validate(value, "numeric|min:4|max:4");
    return res.valid;
  },
});

const simpleWorldZipCodes = new RegExp("^[\\w\\s-]{3,11}$");
extend("plzNonCH", {
  validate(value) {
    return simpleWorldZipCodes.test(value);
  },
});

extend("zipCodeByCountry", {
  params: ["countryCode"],
  async validate(value, {countryCode}) {
    const ruleName = countryCode.toUpperCase() === "CH" ? "plz" : "plzNonCH";
    const res = await validate(value, ruleName);
    return {valid: res.valid, data: countryCode};
    // would require to pass ruleName to defaultMessage
  },
});

const currencyRegEx = new RegExp("^[+-]?[0-9]{1,8}");
extend("currency", {
  validate(value) {
    return currencyRegEx.test(value);
  },
});

export default function ({app}) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`${field}`);
      return app.i18n.t(`validation.${values._rule_}`, values);
    },
  });
};

/** ********************* FEDEX SPECIFIC /***********************/

// match Ascii printable characters
const asciiRegEx = new RegExp("^[ -~\\n]*$");
extend("ascii", {
  validate(value) {
    return asciiRegEx.test(value);
  },
});

const fedexPhoneRegEx = new RegExp("^\\+?[0-9\\s]{10,15}$");
extend("fedexPhone",
  {
    params: ["countryCode"],
    validate(value, {countryCode}) {
      const valid = fedexPhoneRegEx.test(value);
      if (valid && (countryCode === "US" || countryCode === "CA")) {
        return !value.toString().startsWith("001");
      }
      return valid;
    },
  });

extend("northAmericaStateOrProvinceCode",
  {
    params: ["countryCode"],
    validate(value, {countryCode}) {
      if (countryCode === "US") {
        return value in statesUSA;
      }
      if (countryCode === "CA") {
        return value in provincesCanada;
      }
      return true;
    },
  });
