
import Vue, {PropType} from "vue";
import {BIconTrash} from "bootstrap-vue";
import {CHF_ZERO} from "kistenkonfigurator/build/util/currency-util";
import ProductLink from "../product/ProductLink.vue";
import ValidatedField from "../../components/util/form/ValidatedField.vue";
import ProductValueRenderer from "../product/ProductValueRenderer.vue";
import {CartBusinessLogic} from "../../model/CartBusinessLogic";
import {getVisibleProperties, getVisiblePropertyMap} from "../../model/ProductBusinessLogic";
import {ProductAttributeValue} from "../../model/rest/data-contracts";
import {CartItemMutation, LocalCartItem} from "../../model/cart/LocalCartItem";
import {CartModel} from "../../model/checkout/CartModel";

interface State {
  quantity: number;
  customerRef: string | undefined | null;
}

export default Vue.extend({
  name: "CartListItem",
  components: {
    ProductValueRenderer,
    ValidatedField,
    ProductLink,
    BIconTrash,
  },
  props: {
    owner: {
      type: Object as PropType<CartModel>,
      required: true,
    },
    item: {
      type: Object as PropType<LocalCartItem>,
      required: true,
    },
  },
  data(): State {
    return {
      quantity: this.item.quantity,
      customerRef: this.item.customerRef,
    };
  },
  computed: {
    isQuantityEditable(): boolean {
      return (this.item.mutability & CartItemMutation.QUANTITY) > 0;
    },
    isRemoveButtonEnabled(): boolean {
      return (this.item.mutability & CartItemMutation.REMOVE) > 0;
    },
    imageURL(): string {
      return `${(this.$config as any).baseUrl}/api/media/product-overview/${this.item.product.articleNumber}`;
    },
    values(): Array<ProductAttributeValue> {
      const visiblePropertiesMap = getVisiblePropertyMap(getVisibleProperties(this.item.product));

      return this.item.product
          .values
          .filter((v: ProductAttributeValue) => (visiblePropertiesMap[v.productAttribute.attribute!.name] != null || v.productAttribute.visibility > 0) && v.value !== "");
    },
    pricePerOne(): currency {
      return this.item.pricePerOne !== null ? this.item.pricePerOne : CHF_ZERO;
    },
    totalPrice(): currency {
      return CartBusinessLogic.itemTotal(this.item);
    },
  },
  watch: {
    quantity(newValue) {
      // We watch the item itself to check if the item changed outside our component. If this happens, the quantity of the item
      // is already correct, and we don't have to change it inside the store again.
      if (this.item.quantity === newValue) {
        return;
      }

      this.$bl.cartService.getCartModel().updateCount(this.item, parseInt(newValue));
    },
    customerRef(newValue) {
      this.$store.commit("cart/setCustomerRef", {
        item: this.item,
        customerRef: newValue,
      });
    },
    item: {
      // The store can change the quantity of this item from the "outside" if the user adds the same product several times.
      handler(newValue: LocalCartItem) {
        this.quantity = newValue.quantity;
      },
      deep: true,
    },
  },
  methods: {
    removeItem() {
      this.owner.removeItem(this.item);
    },
  },
});
