import {keyBy} from "lodash";

export const state = () => ({
    /** @type {Array<Attribute>} */
    attributes: [],
    id2Attribute: {},
});

export const mutations = {
    setAttributes(state, attributes) {
        state.attributes = attributes;
        state.id2Attribute = keyBy(state.attributes, "id");
    },
    /**
     *
     * @param state
     * @param {Attribute} attribute
     */
    addAttribute(state, attribute) {
        state.attributes.push(attribute);
        state.id2Attribute[attribute.id] = attribute;
    },

    updateAttribute(state, attributeData) {
        const existing = state.id2Attribute[attributeData.id];
        Object.assign(existing, attributeData);
    },
};

export const getters = {
    /**
     * @param state
     */
    getNameFromValue(state) {
        /**
         * @param {ProductAttributeValue} attributeValue
         * @return {string}
         */
        return (attributeValue) => {
            const attribute = state.id2Attribute[attributeValue.attribute.attributeId];
            return attribute != null
                ? attribute.name
                : `[id]: ${attributeValue.attribute.attributeId}`;
        };
    },

    /**
     * @return Array<Attribute>
     */
    all(state) {
        return state.attributes;
    },

    getById(state) {
        /**
         * @param {number} id
         * @returns {Attribute}
         */
        return (id) => {
            return state.id2Attribute[id];
        };
    },
};

export const actions = {
    async loadAll(store) {
        await this.$graphApi.attribute.getAll();
    },
};
