import Vue from "vue";
import Router, {Route} from "vue-router";

Vue.use(Router);

/**
 * Prefix for directories containing special shop pages.
 * @type {string}
 */
const MODE_INDICATOR = "-";

function getRoutesDirectory(ssrContext, shopMode) {
  const currentDirectory = "-" + shopMode;
  let routesDirectory = currentDirectory;

  if (process.server && ssrContext && ssrContext.nuxt) {
    ssrContext.nuxt.routesDirectory = currentDirectory;
  }
  if (process.client) {
    // Get what we saved on SSR
    if (window.__NUXT__ && window.__NUXT__.routesDirectory) {
      routesDirectory = window.__NUXT__.routesDirectory;
    }
  }
  return routesDirectory;
}

export function createRouter(
  ssrContext,
  createDefaultRouter,
  routerOptions,
  config) {

  const options = routerOptions != null
    ? routerOptions
    : createDefaultRouter(ssrContext, config).options;

  return new Router({
    ...options,
    routes: fixRoutes(ssrContext, options.routes, config.shopMode),
  });
}

function fixRoutes(ssrContext, defaultRoutes, shopMode) {
  // default routes that come from `pages/`
  const currentDirectory = getRoutesDirectory(ssrContext, shopMode);

  return defaultRoutes
    .filter((/** Route */ route) => {
      if (route.path.startsWith("/" + MODE_INDICATOR)) {
        return route.path.includes(currentDirectory);
      }
      return true;
    })
    .map((/** Route */ route) => {
      if (route.path.startsWith("/" + currentDirectory)) {
        // Fix named route, so we ca navigate with the name to a specific route.
        const name = route.name != null
          ? route.path.endsWith(currentDirectory)
            ? "index"
            : route.name.substr(currentDirectory.length + 1)
          : undefined;
        return {
          ...route,
          path: route.path.substr(currentDirectory.length + 1) || "/",
          name,
        };
      }
      return route;
    });
}
