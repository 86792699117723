<template>
  <footer>
    <b-container>
      <div class="row">
        <div class="col-6">
          <h5>Bodmer AG</h5>
          <ul class="list-unstyled text-small">
            <li><a href="/kontakt">Kontakt</a></li>
            <li><a href="/labels">Labels</a></li>
            <li><a href="/gallerie">Galerie</a></li>
          </ul>
        </div>
        <div class="col-6"></div>
      </div>
      <div id="connections" class="row">
        <div class="col-12">
          <a class="big-font" href="https://www.instagram.com/palettenmoebel_ch/">
            <b-icon-instagram alt="Besuch uns auf Instagram"/>
          </a>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-5 col-md">
          <p class="mb-0">
            © {{ new Date().getFullYear() }} Bodmer AG
          </p>
        </div>
        <div class="col-7 col-md text-right disclaimer">
          <ul class="list-unstyled text-small mb-0">
            <li><a href="/agb">AGB</a></li><li><a href="/impressum">Impressum</a></li><li><a href="/datenschutz">Datenschutz</a></li>
          </ul>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
import {BIconInstagram} from "bootstrap-vue";
import CookieConsent from "~/components/cookie/CookieConsent";

export default {
  name: "FooterMoebel",
  components: {
    CookieConsent,
    BIconInstagram,
  },
};
</script>
